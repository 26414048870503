import React, { useEffect } from "react"
import Layout from "../layouts/en"
import SEO from "../components/seo"
import ProductList from "../components/productList"
import Logo from "../components/logo"
import { GatsbyImage } from "gatsby-plugin-image"
import sanityConfig from "../../sanity.js"
import * as css from "./index.module.css"
import useSiteMetadata from "../components/use-site-metadata"
// import Helmet from "react-helmet"

const IndexPage = props => {
  const { location, pageContext } = props
  const { langKey } = pageContext
  const data = useSiteMetadata()
  const { sanitySiteSettings } = data

  // useEffect(() => {
  //   if (typeof window === "undefined") {
  //     return
  //   }
  //   const { SearchWidget } = window

  //   if (!SearchWidget) {
  //     return
  //   }

  //   new SearchWidget(
  //     "searchwidget",
  //     "https://platform.hostfully.com/vacation-rental-agency/the-bronson-as",
  //     {
  //       lang: "en",
  //       fields: ["checkIn", "checkOut", "guests"],
  //       maximumGuests: 30,
  //       daysBetweenDates: 7,
  //       openInNewTab: false,
  //       backgroundColorButton: "#FFFFFF",
  //       colorButton: "#000000",
  //     }
  //   )
  // }, [])

  return (
    <Layout hideHeader location={location}>
      <SEO title="The Bronson" />
      <div className={css.hero}>
        <GatsbyImage
          image={sanitySiteSettings.mainImage.asset.gatsbyImageData}
          alt="Apartments"
          className={css.heroImage}
          backgroundColor="#131e40"
          objectFit="cover"
          objectPosition="50% 50%"
        />
        <Logo alt="The Bronson" className={css.logo} langKey={langKey} />

        {/* {sanitySiteSettings?.showSearchWidget && (
          <div className={css.searchwidget}>
            <div id="searchwidget">Loading widget</div>
          </div>
        )} */}
      </div>
      <div className={css.content}>
        <ProductList
          products={sanitySiteSettings.featuredProducts}
          properties={sanitySiteSettings.featuredProperties}
          langKey={langKey}
        />
      </div>
    </Layout>
  )
}

export default IndexPage
